import React from "react";
import About from "../../../components/client/other/About";
import "./About.css"; // Import CSS for About

const AboutPage = () => {
  return (
    <div>
      <About />
    </div>
  );
};

export default AboutPage;
