import React from "react";
import Contact from "../../../components/client/other/Contact";
import "./Contact.css"; // Import CSS for Contact

const ContactPage = () => {
  return (
    <div>
      <Contact />
    </div>
  );
};

export default ContactPage;
