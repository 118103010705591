import React from "react";
import "./About.css"; // Import CSS for About

const About = () => {
  return (
    <section>
      <h2>About Us</h2>
      {/* About section content */}
    </section>
  );
};

export default About;
