import React from "react";
import "./Contact.css"; // Import CSS for Contact

const Contact = () => {
  return (
    <section>
      <h2>Contact Us</h2>
      {/* Contact section content */}
    </section>
  );
};

export default Contact;
